import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="file-remove"
export default class extends Controller {
  static targets = ["checkbox", "button", "fileName", "fileSize"]

  mark(event) {
    if (confirm("Are you sure you want to remove this file?")) {
      this.checkboxTarget.checked = true
      this.element.classList.add("opacity-50")
      this.buttonTarget.disabled = true

      // Add visual indicators for deletion
      this.fileNameTarget.classList.add("line-through", "text-gray-400")
      this.fileSizeTarget.classList.add("text-gray-400")

      // Add a "pending deletion" badge
      const badge = document.createElement("span")
      badge.className = "ml-2 inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10"
      badge.textContent = "Pending Deletion"
      this.fileNameTarget.appendChild(badge)
    }
  }
}
