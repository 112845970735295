import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select";

// Connects to data-controller="tom-select"
export default class extends Controller {
  static values = {
    placeholder: String,
    required: Boolean // Add this new value
  };

  connect() {
    this.initializeTomSelect();
  }

  initializeTomSelect() {
    const tomSelectOptions = {
      // Enable searching in the dropdown
      searchField: "text",

      // Placeholder text
      placeholder: this.placeholderValue || "Select an option",

      // Allow clearing the selection
      allowClear: true,

      // Maximum number of selected items
      maxItems: 1,

      dropdownParent: "body",
    };

    new TomSelect(this.element, tomSelectOptions);
  }
}
